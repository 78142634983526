
.about{
  
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}
.about-container{
  
  max-width: 90rem;
  display: flex;
  height: auto;
}
.about-main{
  display: flex;
  height: auto;
  margin: 190px 0px 200px 0px;
}
.about-container p{

  font-size: 23px;
  text-align: left;
  padding: 0px 15px 30px 15px;
  font-weight: 400;
  margin: 10px 50px 50px 50px ;
  color: white;

}
@media screen and (max-width: 1400px){
	.about-container p{
    
    font-size: 19px;
  }
}
@media screen and (max-width: 1110px){
   .about-main{
  display: flex;
  height: auto;
  margin: 90px 0px 180px 0px;
}
  .about-container{
  	
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  img{
    width: 350px;
    height: 520px;
  }
  .about-container p{
    font-size: 18px;
    margin: 15px;
    color: white;
  }

}
@media screen and (max-width: 810px){
	.about-main{
  display: flex;
  height: auto;
  margin: 90px 0px 60px 0px;
}
  .about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  img{
    width: 343px;
    height: 500px;
  }
  .about-container p{
    font-size: 18px;
    color: white;
  }
  .about h3{
  font-size: 20px;
  
  color: #20B2AA;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
}
}
@media screen and (max-width: 420px){
img{
    width: 280px;
    height: 420px;
  }
}