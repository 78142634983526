.contact{
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 
}
.contact-video{
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 70vh;
  padding: 0;
  margin: 0;
  object-fit: cover;
}
.contact-h{
  font-size: 30px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 200px;
  color: white;
}
.contact h1{
  color: white;
  margin: 0;
  padding: 0;
 
}
.contact-p{
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-p a{
  margin-top: 10px;
  color: black;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}
.contact-p section{
  font-size: 25px;
}
@media screen and (max-width: 1300px){
 .contact-h{
    padding-left: 50px;
  }
  .contact h1{
  font-size: 74px;
}
.contact-p a{
 font-size: 23px;
}
.contact-p section{
  font-size: 22px;
  text-align: center;
}


}
@media screen and (max-width: 1110px){
  .contact-h{
    padding-left: 50px;
  }
  .contact h1{
  font-size: 70px;
}
.contact-p a{
 font-size: 25px;
 
}
.contact-p section{
  font-size: 22px;
  text-align: center;
}
}
@media screen and (max-width: 810px){
  .contact-video{
    height: 50vh;
  }
  .contact-h{
    padding-left: 10px;
    height: 50vh;
  }
  .contact-p{
    height: 40vh;

  }
  .contact h1{
  font-size: 40px;
}
.contact-p a{
 font-size: 14px;
 color: green;

}
.contact-p section{
  font-size: 14px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 30px;
  padding: 3px;
  
}
}