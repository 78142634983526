.App{
 width: 100%;
 height: 100vh;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

}
.content{
 text-align: center;
}
.content h1{
  font-size: 90px;
  color: ghostwhite;
   font-family: "Merriweather", serif;
  font-weight: 500;
  font-style: normal
}
.content h2{
  font-size: 20px;
  font-weight: 400;
  color: white;
  margin: -50px 0px 40px 0px;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.back-video{
 position: absolute;
 z-index: -1;
 margin:0;
 padding: 0;
 object-fit: cover;
 height: 100%;
 width: 100%;
}
.front-butt{
  display: flex;
  justify-content: space-between;
  width: 220px;
  
}
button{
  background-color: transparent;
  border: solid 4px white;
  font-size: 15px;
  color: white;
  padding: 15px;
  cursor: pointer;
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
  
}
.container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  font-size: 30px;
  
}
.container > * {
  margin: 0 10px; /* Adjust spacing between icons */
  color: white;
}

