.home{
 width: 100%;
 height: 100vh;
 min-height: 0px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

}
.content{
 text-align: center;
}
.content h1{
  font-size: 90px;
  color: ghostwhite;
     font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "GRAD" 0;

}
.content h2{
  font-size: 20px;
  font-weight: 400;
  color: white;
  margin: -50px 0px 40px 0px;
  font-family: "Sono", monospace;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "MONO" 1;
}

.back-video{
 position: absolute;
 z-index: -1;
 margin:0;
 padding: 0;
 object-fit: cover;
 height: 100vh;
 width: 100%;
}
.front-butt{
  display: flex;
  width: 320px;
  gap: 15px;
  
}
button{
  background-color: transparent;
  border: solid 2px white;
  font-size: 15px;
  color: white;
  padding: 15px;
  cursor: pointer;
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
  
}
.container {
  position: absolute; 
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  font-size: 30px;
  
}
.container > * {
  margin: 0 10px; /* Adjust spacing between icons */
  color: white;
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .content h1{
     font-size: 60px;
 }
 .content h2{
  font-size: 22px;
  margin: 30px 0px 40px 0px;
 }
}

@media screen and (max-width: 1024px){
   .home{
    height: 100vh;
    width: 100%;
    
  }

 .content h1{
  font-size: 40px;
 }
 .content h2{
  font-size: 25px;
  margin: 30px 0px 40px 0px;
 }
 .front-butt{
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 9rem;
  
  
 }
 button{
  border: solid 2px white;
  font-size:10px;
  width: 80px;
}
.container {
  pdding-bottom: 50%;
  font-size: 20px;
}

}
@media screen and (max-width: 480px){
  .home{
    height: 100vh;
    width: 100%;
    min-height: 600px;
  }
  .content h1{
  font-size: 33px;
 }
 .content h2{
  font-size: 20px;
  margin: 30px 0px 40px 0px;
 }
 .front-butt{
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 9rem;
  
  
 }
 button{
  border: solid 1px white;
  font-size:10px;
  width: 80px;
}
.container {
  pdding-bottom: 50%;
  font-size: 20px;
}
}
@media screen and (max-width: 390px) {
  .home{
    min-height: 600px;
    height: 100vh;
    width: 100%;
  }
  .content h1{
  font-size: 25px;
  color: white;
  
 }
 .content h2{
  font-size: 15px;
  margin: 30px 0px 40px 0px;

 }
 .front-butt{
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 9rem;
  
  
 }
 button{
  border: solid 1px white;
  font-size:10px;
  width: 70px;
  
}
.container {
  margin-bottom: 5%;
  font-size: 20px;
}
}
@media screen and (max-height: 450px){

  .home {
  height: 700px;
  
 }
 .back-video{
  height: 700px;
 }
 .container{
  position: relative;
  margin-top: 30px;
 }
}


