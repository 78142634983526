.Portfolio{
	margin: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.trailer-liya{
	background-color: pink;
	height: 100vh;
	background: url("liya.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.trailer-lijes{
	background-color: blueviolet;
	height: 100vh;
	background: url("lijesp.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	
}
.wanaw{
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 45%;
	justify-content: flex-end;
	gap: 10px;
	padding-left: 50px;
	padding-bottom: 50px;
	color: white;
	
	
}
.wanaw h2{

	font-size: 50px;
	font-family: "Mukta", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.wanaw p{
	font-size: 17px;
	font-family: "Mukta", sans-serif;
  font-weight: 500;
  font-style: normal;
  /*background-color: rgba(255, 255, 255, 0.5);
  color: black;
  padding: 5px;
  border-radius: 5px;*/
}
.wanaw button{
	width: 30%;
	margin-bottom: 3rem;
}
.display{
  
  
  color: black;
  position: fixed;
  top: 0;
  left: -100%; /* initially hide off-screen */
  transition: left 0.35s ease; /* smooth transition */
}

.display.active {
  right: 0; /* slide in from left when active */
  transition: 350ms;
  left: 0;
transition: 350ms;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .wanaw h2{
		font-size: 40px;
		
		
	}
	.trailer-liya{
		background-image: url("liges.JPG");		
	}
	.wanaw button{
	 width: 8rem;
	 }

.wanaw p{
	font-size: 18px;
}
.trailer-lijes{
		background-image: url("llijesp.jpg");
	}
}
@media screen and (max-width: 768px) {
    .wanaw h2{
		font-size: 35px;
		margin-bottom: 0px;		
	}
	.trailer-liya{
		background-image: url("lliya.jpg");	
	}
	.wanaw button{
	 width: 8rem;
	 height: 50px;
	 }
	.wanaw{
	height: 100%;
	min-width: 300px;
	padding: 20px;
	margin: 25px -10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

}
.wanaw p{
	font-size: 16px;
	margin-bottom: 0px;
}
.trailer-lijes{
		background-image: url("llijesp.jpg");
	}
}
