.Navigation{
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	background-color: transparent;
	width: 100%;
	height: 5rem;
	position: fixed;
	color: white;

}
.nav-menu{
	display: flex;
	margin: 0px 5px;
}
.nav-menu a{
	margin: 0px 10px;
	font-size: 20px;
	font-weight: 500;
	cursor: pointer;
	color: white;
	text-decoration: none;

}
.title{
   cursor: pointer;
   font-size: 40px;
   font-weight: 500;
   margin: 40px 0px 0px 45px;
   font-family: "Mukta", sans-serif;
   font-style: normal;
   color: white;
   text-decoration: none;
}
.bars{
	display: none;
}

@media screen and (max-width: 1110px){
	
	.bars{
	font-size: 30px;
	margin-right: 20px;
	color: black;
}
}
@media screen and (max-width: 810px){
	
.bars{
	display: flex;
	font-size: 30px;
	margin-left: 20px;
	margin-top: 0px;
	color: white;
}
.nav-menu{
	display: none;
}
.title{
	display: none;
}
.second-nav-menu {
	background-color: white;
	width: 100%;
	height: 100%;
  color: black;
  position: fixed;
  top: 0;
  left: -100%; /* initially hide off-screen */
  transition: left 0.35s ease; /* smooth transition */
}

.second-nav-menu.active {
  right: 0; /* slide in from left when active */
  transition: 350ms;
  left: 0;
transition: 350ms;
}

}
@media screen and (max-width: 390px){
	.sidebar{
		height: 100vh;
		width: 100%;
	}
	.sidebar-menu a{
		font-size: 20px;
	}
	.sidebar-menu > *{
     margin-top: 50px;
    text-decoration: none;
	}
	.tag-close{
	 font-size: 35px;
    }
}