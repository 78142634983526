.list{
	height: 200px;
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.elements{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 22rem;
  background-color: transparent;
  font-size: 30px;
  
}
.elements > *{
	color: white;
}
.list p{
	font-size: 20px;
  color: white;
}
@media screen and (max-width: 810px){
  .elements{
    font-size: 20px;
    width: 16rem;
  }
  .list p{
    font-size: 15px;
  }

  
}