.sidebar-menu{
	width: 100%;
	height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
	cursor: pointer;
	transition: 0.3s;
    transition: border-bottom 7s ease;
    
}
.sidebar-menu > *{
 margin-top: 80px;
 text-decoration: none;
	
}
.sidebar-menu a{
	color: black;
	font-size: 25px;
	font-weight: 500;	
}
.close-tag{
	
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	


}
.tag-close{
	font-size: 45px;
}
.sidebar-menu :hover{
	padding-bottom: 5px;
	border-bottom: 3px solid #2F4F4F;

}
@media screen and (max-width: 390px){
	.sidebar-menu a{
		font-size: 20px;
	}
	.sidebar-menu > *{
     margin-top: 50px;
    text-decoration: none;
	}
	.tag-close{
	font-size: 35px;
    }
}